/* Google Font Link */
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&family=El+Messiri:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


:root{
  --main-blue-primary: #1a2942;
  --main-blue-secondery: #131d2d;
  --main-prussian-blue: #002b4f;
  --main-picton-blue: #55bbeb;
  --main-electric-violet: #2e3191;
  --main-bright-blue: #0075c6;
  --main-turmeric: #bdd247;
  --main-cerise: #e43592;
  --main-orange: #ee9522;
  --main-coral: #ff8a45;
  --main-white: #ffffff;
  --bs-cyan-white: #bcc4d9;
  --main-periwinkle-gray: #bfcce0;
  --main-fiord-gray: #475467;
  --main-black: #000000;
  --main-light-black: #374151;
  --main-dark-black: #002b4f;
  --main-silder-gray: #cccccc;
  --main-periwinkle-gray: #b4c5e0;

/* Font Style */
  --bs-font-condensed: "Roboto Condensed", sans-serif;
  --bs-font-dancing: "Dancing Script", cursive;
  --bs-font-courgette: "Courgette", cursive;
  --bs-messiri: "El Messiri", sans-serif;
  --bs-font-roboto: "Roboto", sans-serif;
  --bs-font-poppins: "Poppins", sans-serif;
  --bs-font-cormorant: "Cormorant Garamond", serif;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--bs-font-roboto);
}

/* Custom Button Design */
.custom-button{
  background-color: var(--main-electric-violet);
  border: 1px solid var(--main-electric-violet);
}

.custom-button:hover{
  background-color: var(--main-blue-primary) !important;
}

/* Custom NavLink */
.custon-navlink a{
  color: var(--main-black);
  text-decoration: none;
  cursor:copy;
}

.custon-navlink a span{
  
  color: var(--main-electric-violet);
  text-decoration: underline;
  cursor: pointer;
 
}


/* All Pages Same Design */
.all-pages-header-section-container-title {
  max-width: 75%;
  margin: 0 auto;
  padding: 11rem 0rem 0rem 0rem;
}

.all-pages-header-section-container-title h1 {
  color: var(--bs-white);
  font-size: 40px;
  line-height: 56px;
  font-weight: 500;
}

.all-pages-header-section-container-title p {
  color: var(--bs-cyan-white);
  font-family: var(--bs-font-courgette);
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}

.all-pages-header-section-container-title-path{
  display: flex;
  justify-content: left;
  align-items: center;
}

.all-pages-header-section-container-title-path i{
  margin: auto 10px;
}

.all-pages-header-section-container-title-path p:hover{
  color: var(--main-coral);
  cursor: pointer;
}


/* =========================== */
/* Banner Animation Area 1 */
/* =========================== */

.animation-area {
  height: 0px;
}
.box-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.box-area li {
  position: absolute;
  display: block;
  list-style: none;

  background: rgba(255, 255, 255, 0.2);
  animation: animate 15s linear infinite;
  bottom: 10px !important;
  border-radius: 50px;
}
.box-area li:nth-child(1) {
  left: 0%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  animation-duration: 5s;
}
.box-area li:nth-child(2) {
  left: 120%;
  width: 30px;
  height: 30px;
  animation-delay: 0.5s;
  animation-duration: 3s;
}
.box-area li:nth-child(3) {
  left: 70%;
  width: 100px;
  height: 100px;
  animation-delay: 1s;
  animation-duration: 4s;
}
.box-area li:nth-child(4) {
  left: 0%;
  width: 150px;
  height: 150px;
  animation-delay: 1.5s;
  animation-duration: 5s;
}
.box-area li:nth-child(5) {
  left: 165%;
  width: 40px;
  height: 40px;
  animation-delay: 2s;
  animation-duration: 4s;
}
.box-area li:nth-child(6) {
  left: 115%;
  width: 110px;
  height: 110px;
  animation-delay: 0s;
  animation-duration: 4s;
}
.box-area li:nth-child(7) {
  left: 35%;
  width: 35px;
  height: 35px;
  animation-delay: 0.5s;
  animation-duration: 6s;
}
.box-area li:nth-child(8) {
  left: 154%;
  width: 80px;
  height: 80px;
  animation-delay: 1s;
  animation-duration: 5s;
}
.box-area li:nth-child(9) {
  left: 17%;
  width: 60px;
  height: 60px;
  animation-delay: 1.5s;
  animation-duration: 5s;
}
.box-area li:nth-child(10) {
  left: 217%;
  width: 70px;
  height: 70px;
  animation-delay: 2s;
  animation-duration: 7s;
}
.box-area li:nth-child(11) {
  left: 16%;
  width: 34px;
  height: 34px;
  animation-delay: 2.5s;
  animation-duration: 8s;
}
.box-area li:nth-child(12) {
  right: 0%;
  width: 43px;
  height: 43px;
  animation-delay: 0s;
  animation-duration: 3s;
}
.box-area li:nth-child(13) {
  right: 120%;
  width: 42px;
  height: 42px;
  animation-delay: 0.5s;
  animation-duration: 4s;
}
.box-area li:nth-child(14) {
  left: 37%;
  width: 52px;
  height: 52px;
  animation-delay: 1s;
  animation-duration: 6s;
}
.box-area li:nth-child(15) {
  left: 39%;
  width: 50px;
  height: 50px;
  animation-delay: 1.5s;
  animation-duration: 4s;
}

.box-area li:nth-child(16) {
  left: 86%;
  width: 80px;
  height: 80px;
  animation-delay: 2s;
  animation-duration: 5s;
}
.box-area li:nth-child(17) {
  left: 120%;
  width: 30px;
  height: 30px;
  animation-delay: 2.5s;
  animation-duration: 6s;
}
.box-area li:nth-child(18) {
  left: 70%;
  width: 100px;
  height: 100px;
  animation-delay: 0s;
  animation-duration: 7s;
}
.box-area li:nth-child(19) {
  left: 42%;
  width: 150px;
  height: 150px;
  animation-delay: 0.5s;
  animation-duration: 4s;
}
.box-area li:nth-child(20) {
  left: 65%;
  width: 40px;
  height: 40px;
  animation-delay: 1.5s;
  animation-duration: 4s;
}
.box-area li:nth-child(21) {
  left: 15%;
  width: 110px;
  height: 110px;
  animation-delay: 2s;
  animation-duration: 4s;
}
.box-area li:nth-child(22) {
  left: 35%;
  width: 35px;
  height: 35px;
  animation-delay: 2.5s;
  animation-duration: 3s;
}
.box-area li:nth-child(23) {
  left: 54%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  animation-duration: 6s;
}
.box-area li:nth-child(24) {
  left: 17%;
  width: 60px;
  height: 60px;
  animation-delay: 0.5s;
  animation-duration: 7s;
}
.box-area li:nth-child(25) {
  left: 27%;
  width: 70px;
  height: 70px;
  animation-delay: 1s;
  animation-duration: 5s;
}
.box-area li:nth-child(26) {
  left: 16%;
  width: 34px;
  height: 34px;
  animation-delay: 1.5s;
  animation-duration: 3s;
}
.box-area li:nth-child(27) {
  left: 33%;
  width: 43px;
  height: 43px;
  animation-delay: 2s;
  animation-duration: 4s;
}
.box-area li:nth-child(28) {
  left: 22%;
  width: 42px;
  height: 42px;
  animation-delay: 2.5s;
  animation-duration: 5s;
}
.box-area li:nth-child(29) {
  left: 37%;
  width: 52px;
  height: 52px;
  animation-delay: 3s;
  animation-duration: 7s;
}
.box-area li:nth-child(30) {
  left: 39%;
  width: 50px;
  height: 50px;
  animation-delay: 3.5s;
  animation-duration: 4s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-800px) rotate(360deg);
    opacity: 0;
  }
}

/* ================= */
/* Table Design Area */
/* ================= */

.table {
  width: 100% !important;
  border-collapse: collapse;
}

.table td,
.table th {
  padding: 12px 15px;
  border: 1px solid #ccc;
  background-color: #485468;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  word-wrap: break-word;
}

.table th {
  background-color: #1a2942;
  color: #ffffff;
  border: 1px solid #ccc;
}

.table-action-div span:hover:nth-last-child(1){
color: #ff6666;
}

.table-action-div span:hover:nth-last-child(2){
  color: #4da64d;
  }

  .table-action-div span:hover:nth-last-child(3){
    color: #87ceeb;
    }

/* .table td {
  padding: 7px 15px;
} */

/*responsive*/

@media (max-width: 500px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }
  .table tr {
    margin-bottom: 15px;
  }
  .table td {
    padding-left: 40%;
    text-align: left;
    position: relative;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 60%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
}

.pagination {
  display: flex;
  justify-content: right;
  padding: 0;
  list-style: none;
}

.pagination li {
  margin: 0 0.25rem;
}

.pagination a {
  display: block;
  padding: 0.3rem 0.5rem;
  background-color: #566573;
  border: 1px solid #566573;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
}

.pagination .active a {
  background-color: #1c2833;
  color: #ffffff;
}

.pagination a:hover {
  background-color: #1c2833;
  color: #ffffff !important;
}


/* Responsive Design Area */

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 360px) and (max-width: 599px) {
    .all-pages-header-section-container-title{
        max-width: 95%;
    }

    .all-pages-header-section-container-title h1 {
      font-size: 30px;
      line-height: 36px;
    }
    
    .all-pages-header-section-container-title p {
      font-size: 15px;
      line-height: 15px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .all-pages-header-section-container-title{
    max-width: 95%;
}

.all-pages-header-section-container-title h1 {
  font-size: 30px;
  line-height: 36px;
}

.all-pages-header-section-container-title p {
  font-size: 15px;
  line-height: 15px;
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .all-pages-header-section-container-title{
    max-width: 95%;
}

.all-pages-header-section-container-title h1 {
  font-size: 30px;
  line-height: 36px;
}

.all-pages-header-section-container-title p {
  font-size: 15px;
  line-height: 15px;
}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1023px) {
   
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .all-pages-header-section-container-title {
    max-width: 95%;
  }

  .all-pages-header-section-container-title h1 {
    font-size: 32px;
    line-height: 35px;
  }

  .all-pages-header-section-container-title p {
    font-size: 14px;
    line-height: 20px;
  }
}