.video-wrapper {
    height: 100vh;
    position: relative;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* filter: blur(2px); */
  }
  .content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    text-align: end;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(14, 20, 54, 0.6),
      rgba(14, 20, 54, 0.6),
      rgba(14, 20, 54, 0.6),
      rgba(14, 20, 54, 0.6),
      rgba(14, 20, 54, 0.6)
    );
    pointer-events: none;
  }
  
  /* ================================ */
  /* Banner Documentent Design Start */
  /* ================================ */
  
  .banner-document-section {
    text-align: center;
    width: 75%;
    margin: auto;
  }
  
  .banner-document-section h3 {
    color: var(--main-white);
    margin-top: 3rem !important;
    font-size: 53px;
    font-weight: bold;
    font-family: var(--bs-font-poppins);
    margin: 5rem auto 1rem auto;
  }
  
  .banner-document-section h3 span {
    color: var(--main-coral);
    font-family: var(--bs-messiri);
  }
  
  .banner-document-services-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 1rem 0rem;
  }
  
  .banner-document-services-div a {
    text-decoration: none;
    color: var(--main-white) !important;
    cursor: pointer;
  }
  
  .banner-document-services-div p {
    margin: 10px 10px;
    padding: 10px 30px;
    /* border: 1px solid var(--main-coral); */
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    display: inline-block;
    transition: transform 0.3s ease;
    font-size: 16px;
  }
  
  .banner-document-services-div p:hover {
    transform: translateY(-5px);
    border: 1px solid rgba(255, 138, 69, 0.5);
  }
  
  .banner-document-services-div p svg {
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }
  
  /* ================================ */
  /* Button Design Home Banner  */
  /* ================================ */
  
  .banner-document-content-btn {
    display: inline-block;
    color: var(--main-white);
    padding: 32px;
    position: relative;
    letter-spacing: 1px;
    font-size: 18px;
    margin-top: 1.3rem;
    margin-bottom: 1.8rem;
    cursor: pointer;
  }
  .btn__circle,
  .btn__text,
  .btn__white-circle {
    position: absolute;
  }
  .btn__circle {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 100%;
    width: 100%;
    box-shadow: 0 0 1px 1px var(--main-white);
    transition: 0.3s linear;
  }
  .btn__white-circle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background: var(--main-coral);
    display: flex;
    transition: 0.3s ease-in-out;
  }
  .btn__white-circle svg {
    width: 24px;
    height: 24px;
    margin: auto;
  }
  .btn__text {
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 2;
    padding: 24px 8px;
    transition: 0.3s linear;
  }
  .banner-document-content-btn:hover .btn__circle {
    transform: scale(0);
  }
  .banner-document-content-btn:hover .btn__white-circle {
    transform: translate(-50%, -50%) scale(1);
  }
  .banner-document-content-btn:hover .btn__text {
    transform: translate(40px, -50%);
  }
  .banner-document-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -15rem;
  }
  
  /* ================================ */
  /* Counter Design Area */
  /* ================================ */
  
  .counter-section {
    margin: 5rem auto;
    width: 75%;
  }
  
  .counter-card {
    height: 240px;
    text-align: center;
    padding: 30px 10px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    transition: transform 0.3s ease;
    width: 100% !important;
  }
  
  .counter-card:hover {
    transform: translateY(-5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  
  .counter-card h2 {
    color: var(--main-black);
    font-size: 80px;
    font-weight: 900;
  }
  
  .counter-card h2 i {
    color: var(--main-coral);
    font-size: 40px;
  }
  
  .counter-card h6 i {
    color: var(--main-coral);
    font-size: 40px;
    margin-bottom: 5px;
  }
  
  .counter-card p {
    padding: 15px auto 0px auto;
    font-size: 25px;
    font-weight: 500;
    margin: 0px;
  }

  .contact-section{
    margin: 8rem auto;
    width: 75%;
  }
  
  
  /* ================================ */
  /* Responsive Design Area */
  /* ================================ */
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 360px) and (max-width: 599px) {
    .banner-document-section {
      width: 95%;
    }
  
    .banner-document-section h3 {
      font-size: 25px;
      margin: 3rem auto 2rem auto;
    }
  
    .banner-document-services-div p {
      margin: 5px 5px;
      padding: 5px 10px;
      font-size: 12px;
    }
  
    .banner-document-services-div p svg {
      margin-right: 7px;
      height: 20px;
      width: 20px;
    }
  
    .banner-document-content-btn {
      padding: 20px;
      font-size: 14px;
      margin-top: 1rem;
    }
  
    .banner-document-content {
      justify-content: flex-start;
      margin-left: 5rem;
    }
  
    .banner-document-services-div h3{
      margin-top: 8rem !important;
      font-size: 1.5rem;
    }
  
    .counter-section {
      width: 95%;
    }
  
    .counter-card {
      height: 180px;
      padding: 30px 10px;
    }
  
    .counter-card h2 {
      font-size: 50px;
      font-weight: 900;
    }
    
    .counter-card h2 i {
      font-size: 30px;
    }
    
    .counter-card h6 i {
      font-size: 30px;
    }
    
    .counter-card p {
      padding: 15px auto 0px auto;
      font-size: 15px;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .banner-document-section {
      width: 100%;
    }
  
    .banner-document-section h3 {
      font-size: 32px;
    }
  
    .banner-document-services-div p {
      margin: 5px 5px;
      padding: 5px 10px;
      font-size: 12px;
    }
  
    .banner-document-services-div p svg {
      margin-right: 7px;
      height: 20px;
      width: 20px;
    }
  
    .banner-document-content {
      justify-content: flex-start;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .banner-document-section {
      width: 80%;
    }
  
    .banner-document-section h3 {
      font-size: 42px;
    }
  
    .banner-document-services-div p {
      margin: 5px 5px;
      padding: 10px 10px;
      font-size: 16px;
    }
  
    .banner-document-services-div p svg {
      margin-right: 7px;
      height: 30px;
      width: 30px;
    }
  
    .counter-section {
      width: 95% !important;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 993px) and (max-width: 1023px) {
    .banner-document-services-div {
      margin: 1rem 0rem;
    }
  
    .banner-document-content-btn {
      padding: 22px;
      font-size: 16px;
      margin-top: 1rem;
    }
  
    .banner-document-section {
      width: 85%;
    }
  
    .banner-document-section h3 {
      font-size: 48px;
      margin: 7rem auto 1rem auto;
    }
  
    .banner-document-services-div p {
      margin: 7px 10px;
      padding: 10px 30px;
      font-size: 14px;
    }
  
    .banner-document-services-div p svg {
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }
  
    .counter-section {
      width: 85%;
    }
  
    .counter-card h2 {
      font-size: 60px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .banner-document-services-div {
      margin: 1rem 0rem;
    }
  
    .banner-document-content-btn {
      padding: 22px;
      font-size: 16px;
      margin-top: 1rem;
    }
  
    .banner-document-section {
      width: 75%;
    }
  
    .banner-document-section h3 {
      font-size: 48px;
      margin: 7rem auto 1rem auto;
    }
  
    .banner-document-services-div p {
      margin: 7px 10px;
      padding: 10px 30px;
      font-size: 14px;
    }
  
    .banner-document-services-div p svg {
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }
  
    .counter-card h2 {
      font-size: 60px;
    }
  
    .counter-card p {
      font-size: 20px;
    }
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .banner-document-section {
      width: 95%;
    }
  
    .banner-document-services-div h3{
      margin-top: 8rem !important;
      font-size: 2.5rem !important;
    }
  }

  @media screen and (min-width: 1395px) and (max-width: 1550px) {
    
  
    .banner-document-services-div h3{
      margin-top: 8rem !important;
      font-size: 2rem !important;
    }
  }


  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .contact-section{
      width: 95%;
    }
  }