.top-header {
    background-color: var(--main-blue-secondery);
    color: var(--main-white);
    padding: 8px;
    margin: 0px;
  }
  
  .top-header-section {
    margin: 0px auto;
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  
  .top-header-section i {
    font-size: 16px;
    cursor: pointer;
    color: var(--main-white);
  }
  
  .top-header-section i:hover {
    color: var(--main-coral);
  }
  
  .top-header-section span {
    letter-spacing: 1px;
    font-size: 14px;
  }
  
  .top-header-section a {
    text-decoration: none;
    color: var(--main-white);
  }
  
  .header {
    padding: 0px 0px 10px 0px;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: all 0.1s ease-in-out;
    /* background: rgb(233, 134, 240, 0.6); */
    /* background-color: var(--main-white); */
    /* background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(300px); */
  }
  
  .header.fixed {
    opacity: 0;
    transform: translateY(-50%);
  }
  
  .header.active {
    transition: all 0.8s ease-in-out;
    background-color: var(--main-white);
    backdrop-filter: blur(2em);
    padding: 0px 0px 10px 0px;
    opacity: 1;
    transform: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  
  .container {
    max-width: 75%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }
  
  nav {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    flex-wrap: wrap;
    height: 4rem;
  }
  nav .logo img {
    margin: 0px;
    padding: 0.5rem 0rem 3.5rem 0rem;
    width: 150px;
    cursor: pointer;
  }
  
  /* nav .logo h1 {
        margin: 0px;
        padding: 0.5rem 0rem 1.5rem 0rem;
        width: 160px;
        cursor: pointer;
        font-family: "Babylonica", cursive;
        font-size: 70px;
        font-weight: 500;
        color: white;
      } */
  
  nav ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px 0px 0px 0px;
  }
  
  .scrolled{
    cursor: pointer;
    text-decoration: none;
    color: var(--main-black);
    font-size: 16px;
    font-weight: 400;
    margin: 0px 40px;
    width: 100%;
    letter-spacing: 1px;
  }
  
  .scrolled:hover{
    color: var(--main-coral);
    text-decoration: none;
  }
  
  .scrolled::after {
    content: "";
    display: block;
  }
  
  .nav-links {
    cursor: pointer;
    text-decoration: none;
    color: var(--main-white);
    font-size: 16px;
    font-weight: 400;
    margin: 0px 40px;
    width: 100%;
    letter-spacing: 1px;
  }
  
  .nav-links:hover {
    color: var(--main-coral);
    text-decoration: none;
  }
  
  .nav-links::after {
    content: "";
    display: block;
  }
  
  .down-arrow {
    padding-left: 8px;
  }
  
  nav .scroll-menu-btn i{
    color: var(--main-white);
    font-size: 22px;
    cursor: pointer;
    display: none;
  }
  
  nav .menu-btn i {
    color: var(--main-black);
    font-size: 22px;
    cursor: pointer;
    display: none;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 215px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-bottom: 2px solid var(--main-coral);
    border-top: 2px solid var(--main-coral);
    left: 70%;
    transform: translateX(-50%);
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
    color: var(--main-coral);
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Shopping Icon */
  .cart-icon2 {
    color: black;
    font-size: 18px;
    display: inline-block;
    border-radius: 50%;
  }
  
  .cart-total-2 {
    background: white;
    border: 1px solid #2e3d60;
    color: white;
    color: #2e3d60;
    font-weight: bold;
    position: relative;
    top: -15px;
    left: -10px;
    font-size: 10px;
    padding: 3px 6px;
    border-radius: 100%;
    display: inline-block;
  }
  /* 
  @media (max-width: 1000px) {
    nav {
      padding: 0 40px 0 50px;
    }
  
    .container {
      width: 100%;
    }
  } */
  @media (max-width: 1024px) {
    nav .menu-btn i {
      display: block;
    }
  
    nav .scroll-menu-btn i{
      display: block;
    }
  
    #click:checked ~ .scroll-menu-btn i:before {
      content: "\f00d";
    }
  
    #click:checked ~ .menu-btn i:before {
      content: "\f00d";
    }
  
    nav ul {
      position: fixed;
      top: 138px;
      left: -100%;
      background: white;
      height: 100vh;
      width: 100%;
      text-align: center;
      display: block;
      transition: all 0.3s ease;
    }
    #click:checked ~ ul {
      left: 0;
    }
    nav ul li {
      width: 100%;
      margin: 40px 0;
    }
    nav ul li .nav-links {
      width: 100%;
      margin-left: -100%;
      display: inline-block;
      font-size: 20px;
      transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      text-align: center;
      color: black;
    }
  
    #click:checked ~ ul li .nav-links {
      margin-left: 0px;
    }
    nav ul li .nav-links.active,
    nav ul li .nav-links:hover {
      background: none;
      color: black;
    }
  
    .dropdown-content {
      left: 50%;
      transform: translateX(-50%);
    }
  
    .scrolled{
      color: var(--main-black);
    }
  }
  
  /* ============================ */
  /* Respinsive Design Area */
  /* ============================= */
  
  /* Extra small devices (phones, 600px and down) */
  
  @media only screen and (max-width: 390px) {
  
  }
  
  
  
  
  @media only screen and (max-width: 600px) {
    .container {
      max-width: 100%;
    }
  
    nav .logo img {
      padding: 0.5rem 0rem 0.5rem 0rem;
      width: 140px;
    }
  
    .top-header-section {
      width: 95%;
      justify-content: center;
    }
  
    .container {
      width: 100% !important;
    }
  
    .admin-access-button{
      display: none;
    }
  
  
  
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media screen and (min-width: 540px) and (max-width: 767px) {
    nav ul {
      top: 115px;
    }
  }
  
  
  /* iPad View Design */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .top-header-section {
      width: 95%;
    }
    .container {
    max-width:100% !important;
    min-width: 100% !important;
    width: 100% !important;
    }
  
    nav .logo img {
      padding: 0.5rem 0rem 0.5rem 0rem;
      width: 150px;
    }
    nav ul {
      top: 115px;
    }
  }
  
  /* Laptop View Design */
  @media screen and (min-width: 1025px) and (max-width: 1366px) {
    .top-header-section {
      width: 95%;
    }
  
    .container {
      max-width: 95%;
    }
  }