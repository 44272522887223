.about-us-pages-header-container {
    background: linear-gradient(
        to right,
        rgba(14, 20, 54, 0.9),
        rgba(14, 20, 54, 0.9),
        rgba(14, 20, 54, 0.9),
        rgba(14, 20, 54, 0.9),
        rgba(14, 20, 54, 0.9)
    ),
    url(https://res.cloudinary.com/asthait/images/f_auto,q_auto/v1686052913/Workplace-Design-For-A-Software-Company-1/Workplace-Design-For-A-Software-Company-1.jpg?_i=AA)
        center center/cover no-repeat padding-box local;
    height: auto;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.about-us-section{
    margin: 6rem auto;
    width: 75%;
}

.about-us-we-are-div{
    margin: 7rem auto;
}

.about-us-we-are-info-div h4{
    font-size: 40px;
    font-weight: 600;
    margin: 20px auto;
}

.about-us-we-are-info-div p{
    font-size: 18px;
}

.about-us-we-are-info-div img{
    border-radius: 15px;
    width: 100%;
    height: 400px;
}

.about-us-growth-div{
    margin: 5rem auto;
}

.about-us-growth-div h3{
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--main-blue-primary);
}

.about-us-growth-div h3 span{
    color: var(--main-coral);
}

.about-us-growth-div p{
    text-align: center;
    margin-bottom: 4rem;
    font-size: 18px;
    color: var(--main-light-black);
}


/* =============Test================= */

.growth-item {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
}
.growth-item .growth-image {
    padding: 1em 2em;
}
.growth-item .growth-image > div {
    position: relative;
    text-align: center;
    font-size: 0.8em;
}
.growth-item .growth-image > div::after {
    content: '';
    width: 100%;
    height: 0;
    border-bottom: 1px solid var(--main-blue-secondery);
    position: absolute;
    top: 2.5em;
    left: 2.5em;
    z-index: -1;
}

.growth-item .growth-image span {
    display: block;
    clear: both;
    padding: 1rem 1.5rem;
    margin: 0.5em 0;
    background: #3b4262;
    font-size: 20px;
    color: var(--main-white);
    border-radius: 50%;
}
.growth-item .growth-details {
    position: relative;
    flex-grow: 1;
}
.growth-item .growth-details > div {
    border: 1px solid var(--main-blue-secondery);
    border-radius: 0.5em;
    padding: 1.5em;
    margin: 1em 0;
}
.growth-item .growth-details > div h1 {
    color: var(--main-blue-primary);
    font-size: 1.4em;
    margin: 0;
    padding: 0 0 0.5em 0;
    letter-spacing: 1px;
}
.growth-item .growth-details > div p {
    margin: 0;
    padding: 0;
    line-height: 150%;
    text-align: start;
}
.growth-item .growth-details::before {
    content: '';
    width: 0;
    height: 100%;
    border-left: 1px solid var(--main-blue-secondery);
    position: absolute;
    top: 0;
    left: -3.9em;
    z-index: -1;
}

.about-us-md-message{
    text-align: center;
    margin: 8rem auto;
}

.about-us-md-message h2{
    font-size: 34px;
    width: 80%;
    margin: 30px auto;
    color: var(--main-blue-secondery);
}

.about-us-md-message img{
    margin: 1rem auto;
    width: 100px;
    height: 100px;
    border: 1px solid var(--main-coral);
    border-radius: 50%;
    padding: 5px;
}

.about-us-md-message h6{
    font-size: 18px;
    font-weight: 800;
}

.about-us-md-message p{
    color: var(--main-coral);
}

.about-us-business-partner-div{
    margin: 10rem auto;
}



@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .about-us-section{
        margin: 4rem auto;
        width: 95%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .about-us-section{
        margin: 4rem auto;
        width: 90%;
    }

    .about-us-md-message h2{
        font-size: 25px;
        width: 95%;
        text-align: justify;
    }
}


@media only screen and (min-width: 360px) and (max-width: 599px) {
    .about-us-section{
        margin: 1rem auto !important;
        width: 85%;
    }

    .about-us-we-are-info-div h4{
        font-size: 26px;
    }
    
    .about-us-we-are-info-div p{
        font-size: 15px;
    }
    
    .about-us-we-are-info-div img{
        height: 250px;
    }

    .about-us-growth-div{
        margin: 3rem auto;
    }

    
.about-us-growth-div h3{
    font-size: 26px;
    font-weight: 600;
}

.about-us-growth-div p{
    margin-bottom: 4rem;
    font-size: 15px;
}

.growth-item {
    max-width: 100% !important;
}

.growth-item .growth-image {
    padding: 1em 1em;
}

.growth-item .growth-image span {
    padding: 1rem 1.3rem;
    margin: 0.5em 1.5em 0.5em 0em;
    font-size: 15px;
}

.growth-item .growth-image > div::after {
    left: 1.3em;
}

.about-us-md-message h2{
    font-size: 20px;
    width: 100%;
    text-align: justify;
}
}