.home-project-section{
    margin: 3rem  auto;
    padding: 6rem 0rem 2rem 0rem;
    width: 75%;
}

.home-project-title-div{
    text-align: center;
}

.home-project-title-div h3{
    color: var(--main-prussian-blue);
    font-weight: 800;
    font-size: 48px;
}

.home-project-title-div h3 span{
    color: var(--main-coral);
}

.home-project-title-div p{
    color: var(--main-fiord-gray);
    font-weight: 400;
    font-size: 19px;
}

  /* =========================== */
  /* Fileter Button Design Area */
  /* ========================== */

  .project-filter-button-div{
    margin: 4rem auto 0rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
  }

  .project-filter-button-div-button{
    margin: 10px;
    padding: 6px 20px;
    border-radius: 10px;
    border: 1px solid var(--main-coral);
    background-color: var(--main-white);
  }

  .project-filter-button-div-button.active {
    background: rgb(255, 138, 69, 1);
    background: linear-gradient(0deg, rgba(238, 149, 34, 1) 0%, rgba(255, 138, 69, 1) 100%); 
    color: var(--main-white); 
  }


  .projects-card-div{
    margin: 0rem auto 10rem auto;
  }

  .projects-card-custom-div{
    margin: 1rem auto;
  }

  /* ============================== */
  /* Project Details Design Area */
  /* ============================== */

  .project-details-information-div{

  }

  .project-details-information-title{
    color: var(--main-blue-secondery);
    font-weight: 500;
    font-size: 25px !important;
  }

  .project-details-information-technology{
    font-size: 19px;
    color: var(  --main-light-black);
    margin-bottom: 1.4rem;
  }

  .project-details-information-description{
    font-size: 17px;
    font-weight: 400;
    color: var(--main-black);
    margin: 2rem auto;
  }

  .project-details-information-video{
    margin: 2rem auto;
  }

  .project-details-information-images-div{
    margin: 2rem auto 2rem auto;
  }

  .project-details-information-images img{
width: 100%;
height: 440px;
  }


@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .home-project-section{
        margin: 3rem  auto;
        padding: 5rem 0rem 2rem 0rem;
        width: 95%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-project-section{
        margin: 2rem  auto;
        padding: 3rem 0rem 2rem 0rem;
        width: 95%;
    }
}

  @media only screen and (max-width: 599px) {
    .home-project-section{
        margin: 2rem  auto;
        padding: 3rem 0rem 2rem 0rem;
        width: 100%;
    }

    .home-project-title-div h3{
        font-size: 25px;
    }

    .home-project-title-div p{
        font-size: 14px;
    }
}