.home-contact-footer-section{
    margin: 3rem  auto 3rem auto;
    padding: 1rem 0rem;
   
    /* background-color: var(--main-blue-primary); */
    border-radius: 15px;
    background: linear-gradient(
        90deg,
        rgba(14, 20, 54, 0.8),
        rgba(14, 20, 54, 0.8),
        rgba(14, 20, 54, 0.8),
        rgba(14, 20, 54, 0.8),
        rgba(14, 20, 54, 0.8)
    ), url(../../Images/banner.jpg);
    background-repeat: repeat;
    background-position: 100% 100%;
    background-size: 55%;
}

.home-contact-footer-section-custom{
    width: 75%;
}

.home-contact-footer-div{
    text-align: center;
    padding: 40px 0px;
}

.home-contact-footer-div h6{
    color: var(--main-white);
    background-color: var(--main-coral);
    padding: 10px 10px;
    font-size: 18px;
    margin: auto;
    width: 14%;
    border-radius: 30px;
}

.home-contact-footer-div h3{
    color: var(--main-white);
    margin: 30px auto 10px auto;
    padding: 10px 0px 10px 0px;
    font-size: 42px;
}

.home-contact-footer-div p{
    color: var(--main-coral);
    font-size: 18px;
}


@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .home-contact-footer-section-custom{
        width: 95%;
    }

    .home-contact-footer-div h6{
        font-size: 23px;
        width: 30%;
    }
    
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .home-contact-footer-section-custom{
        width: 95%;
    }

    .home-contact-footer-div h6{
        font-size: 20px;
        width: 30%;
    }
  }

  @media only screen and (max-width: 599px) {
    .home-contact-footer-section-custom{
        width: 97%;
    }

    .home-contact-footer-section{
        border-radius: 15px;
        /* background: linear-gradient(
            90deg,
            rgba(14, 20, 54, 0.8),
            rgba(14, 20, 54, 0.8),
            rgba(14, 20, 54, 0.8),
            rgba(14, 20, 54, 0.8),
            rgba(14, 20, 54, 0.8)
        ), url(../../Images/banner.jpg); */
        background: none;
        background-color: var(--main-prussian-blue);
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: 100%;
        height: 100%;
    }

    .home-contact-footer-div h6{
        font-size: 14px;
        width: 50%;
    }

    .home-contact-footer-div h3{
        font-size: 25px;
    }

    .home-contact-footer-div p{
        font-size: 14px;
    }

  }