.contact-section{
    margin: 8rem auto;
    width: 75%;
  }

  .contact-form-header h3{
font-size: 32px;
font-weight: 500;
color: var(--main-blue-secondery);
margin-bottom: 40px;
  }

  .contact-form-header h3 span{
color: var(--main-coral);
  }


  .contact-form-address-details{
    padding: 20px;
    background-color: #f5f4f4;
    margin: 20px auto;
    border-radius: 10px;
  }

  .contact-form-address-details h4{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 25px;
  }

  .contact-form-address-details p{
    font-size: 16px;
  }
  
  .contact-form-address-details p i{
    margin-right: 10px;
  }

  .contact-form-submit-btn button{
    border: 1px solid var(--main-coral);
    background-color: var(--main-coral);
    padding: 13px 30px;
    border-radius: 10px;
    color: var(--main-white);
  }

  .contact-form-submit-btn button:hover{
    color: var(--main-coral);
    background-color: var(--main-white);
  }

  .contact-google-map-div{
    margin: 3rem auto 3rem auto;

  }


  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .contact-section{
      width: 95%;
    }
  }