.home-client-review-section {
    margin: 3rem auto;
    padding: 5rem 0rem;
    width: 75%;
  }
  
  .home-client-review-title-div {
    text-align: center;
  }
  
  .home-client-review-title-div h3 {
    color: var(--main-prussian-blue);
    font-weight: 800;
    font-size: 48px;
  }
  
  .home-client-review-title-div h3 span {
    color: var(--main-coral);
  }
  
  .home-client-review-title-div p {
    color: var(--main-fiord-gray);
    font-weight: 400;
    font-size: 19px;
  }
  
  .home-client-review-body-div {
    margin: 5rem auto;
    width: 100%;
  }
  
  .testimonial-container {
    background-color: var(--main-blue-primary);
    color: #fff;
    border-radius: 0px;
    margin: 20px auto;
    padding: 50px 80px;
    width: 100%;
    position: relative;
  }
  
  .fa-quote {
    color: rgba(255, 255, 255, 0.3);
    font-size: 28px;
    position: absolute;
    top: 70px;
  }
  
  .fa-quote-left {
    left: 40px;
  }
  
  .fa-quote-right {
    right: 40px;
  }
  
  .testimonial {
    line-height: 28px;
    text-align: justify;
    font-style: italic;
  }
  
  .user {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .user .user-image {
    border-radius: 50%;
    height: 75px;
    width: 75px;
    object-fit: cover;
  }
  
  .user .user-details {
    margin-left: 10px;
  }
  
  .user .username {
    margin: 0;
  }
  
  .user .role {
    font-weight: normal;
    margin: 10px 0;
  }
  
  .progress-bar {
    /* background-color: #fff;
      height: 4px;
      width: 100%;
      transform-origin: left;
      animation: grow 5.4s linear infinite; */
    margin-bottom: 20px;
  }
  
  @keyframes grow {
    0% {
      transform: scaleX(0);
    }
  }
  
  @media (max-width: 768px) {
    .testimonial-container {
      padding: 20px 30px;
    }
  
    .fa-quote {
      display: none;
    }
  
    .home-client-review-body-div {
      width: 95% !important;
    }
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .home-client-review-section {
      margin: 1rem auto;
      padding: 4rem 0rem;
      width: 95%;
    }
  
    .home-client-review-body-div {
      width: 95% !important;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-client-review-section {
      width: 95%;
    }
  
    .home-client-review-body-div {
      width: 95% !important;
    }
  }
  
  @media only screen and (max-width: 599px) {
    .home-client-review-section {
      width: 100%;
    }
  
    .home-client-review-title-div h3 {
      font-size: 25px;
    }
  
    .home-client-review-title-div p {
      font-size: 14px;
    }
  
    .testimonial {
      line-height: 14px;
      font-weight: 300;
      line-height: 1.3rem;
    }
  
    .home-client-review-body-div {
      width: 85% !important;
    }
  }