.home-technology-section{
    margin: 1rem  auto;
    width: 75%;
}

.home-technology-title-div{
    text-align: center;
}

.home-technology-title-div h3{
    color: var(--main-prussian-blue);
    font-weight: 800;
    font-size: 48px;
}

.home-technology-title-div h3 span{
    color: var(--main-coral);
}

.home-technology-title-div p{
    color: var(--main-fiord-gray);
    font-weight: 400;
    font-size: 19px;
}

.technology-select-button-div {
    display: block;
}

.home-technology-body-div{
    margin: 6rem auto;
}

.technology-select-button{
    margin: 0px;
    padding: 10px 20px;
    width: 100%;
    text-align: start;
    border-radius: 0px;
    border: 1px solid var(--main-coral);
    color: var(--main-black);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    font-size: 16px;
}
  
  .technology-select-button.active {
    background: rgb(255, 138, 69, 1);
    background: linear-gradient(0deg, rgba(238, 149, 34, 1) 0%, rgba(255, 138, 69, 1) 100%); 
    color: var(--main-white); 
  }

  .technology-select-button-icon-div{
    text-align: center;
  }

  .technology-select-button-icon-div img{
    width: 40%;
    height: 60px;
    transition: filter 0.1s ease;
  }

  .technology-select-button-icon-div img:hover{
    filter: brightness(40%);
  }


/* Responsive Design Area */

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 599px) {
    .home-technology-section{
        width: 100%;
    }

    .home-technology-title-div h3{
        font-size: 25px;
    }

    .home-technology-title-div p{
        font-size: 14px;
    }

    .technology-select-button-div {
        display: flex !important;
        flex-wrap: wrap;
    }

    .technology-select-button{
      margin: 5px 10px;
      padding: 10px 20px;
      width: 100%;
      text-align: start;
      border-radius: 0px;
      border: 1px solid var(--main-coral);
      color: var(--main-black);
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      font-size: 13px;
  }

  .technology-select-button-icon-div-col{
    width: 50%;
  }

    .technology-select-button-icon-div img{
        width: 30%;
      }

      .home-technology-body-div{
        margin: 2rem auto;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .technology-select-button-div {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .technology-select-button{
        margin: 10px;
        width: auto;
    }


  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .technology-select-button-div {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .technology-select-button{
        margin: 10px;
        width: auto;
    }

    .home-technology-section{
      width: 95%;
  }
  
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1023px) {
     
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
     
  }

  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .home-technology-section{
        width: 95%;
    }
  }