.footer-section {
    padding: 5rem 0rem 5rem 0rem;
    background-color: var(--main-blue-primary);
  }
  
  .footer-container {
    margin: 0px auto;
    width: 75%;
  }
  
  .footer-logo-div img {
    width: 180px;
  }
  
  .footer-logo-div p {
    margin-top: 1rem;
    color: var(--main-periwinkle-gray);
  }
  
  .footer-logo-social-div {
    margin: 1rem auto 0rem auto;
  }
  
  .footer-logo-social-div span {
    margin: 13px 13px 13px 0px;
    background-color: var(--main-white);
    padding: 8px 10px 5px 10px;
    border-radius: 50%;
    display: inline-block;
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 1px solid;
  }
  
  .footer-logo-social-div span:hover {
    transform: translateY(-5px);
  }
  
  .footer-logo-social-div span i {
    font-size: 17px;
    color: var(--main-blue-primary);
  }
  
  .footer-services-div h5 {
    font-size: 25px;
    margin-bottom: 1.5rem;
  }
  
  .footer-services-div p {
    text-decoration: none;
    color: var(--main-periwinkle-gray);
    font-size: 16px;
    cursor: pointer;
  }
  
  .footer-services-div p:hover {
    color: var(--main-coral);
    text-decoration: underline;
  }
  
  .footer-services-div p {
    padding: 5px 0px 5px 0px;
    margin: 0px;
  }
  
  .footer-address-div h5 {
    font-size: 25px;
    margin-bottom: 1.5rem;
  }
  
  .footer-address-div p {
    color: var(--main-periwinkle-gray);
    margin: 0px;
    padding: 5px 0px;
    font-size: 16px;
  }
  
  .footer-address-div p span {
    margin-right: 10px;
    color: var(--main-white);
  }
  
  .footer-bottom-section {
    text-align: center;
    margin: 0px;
    padding: 20px 0px 20px 0px;
    background-color: var(--main-blue-secondery);
  }
  
  .footer-bottom-section p {
    font-size: 15px;
    color: var(--main-periwinkle-gray);
    margin: 0px auto;
  }
  
  .footer-bottom-section span {
    margin: 0px 3px;
  }

/* =================================== */
  /* FAQ Design Area Start */
  /* =============================== */

  .faq-section-title-div h2{
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--main-blue-primary);
   }
 
   .faq-section-title-div h2 span{
     color: var(--main-coral);
   }
 
   .faq-section-title-div p{
     text-align: center;
     margin-bottom: 4rem;
     font-size: 18px;
     color: var(--main-light-black);
   }
 
 /* ===================== */
   /* Collaps Design Area*/
 /* ===================== */
 
 .faq-body-container-col {
     padding: 0px 0px;
     width: 100%;
     margin: auto;
   }
   
   .faq-body-container-info-question-div:focus {
     border: 1px solid var(--main-coral);
   }
   
   .faq-body-container-info-question {
     font-family: "Barlow", sans-serif;
     color: var(--main-blue-primary);
     font-size: 20px !important;
     font-weight: 500 !important;
     width: 100%;
     margin: 1rem 0rem !important;
     padding: 0px !important;
   }
   
   .faq-body-container-info-answer {
     color: var(--main-fiord-gray);
     font-size: 17px !important;
     text-align: justify;
     font-weight: 400;
     margin: 5px 20px !important;
   }
   
   .faq-question-answer-div-btn {
     text-align: center;
     margin: 10rem auto;
   }
   
   .faq-question-answer-div-btn button span {
     font-style: italic;
   }
   
   .faq-question-answer-div-btn button:nth-child(1) {
     color: var(--main-coral);
     background-color: var(--main-white);
     border: 2px solid var(--main-coral);
     padding: 5px 15px;
     width: 200px;
     margin: 15px;
     text-overflow: ellipsis;
     overflow: hidden;
     height: 3em;
     white-space: nowrap;
   }
   
   .faq-question-answer-div-btn button:hover:nth-child(1) {
     color: var(--main-white);
     background-color: var(--main-coral);
   }
   
   .faq-question-answer-div-btn button:nth-child(2) {
     color: var(--main-white);
     background-color: var(--main-coral);
     border: 2px solid var(--main-coral);
     padding: 5px 15px;
     width: 200px;
     margin: 15px;
     text-overflow: ellipsis;
     overflow: hidden;
     height: 3em;
     white-space: nowrap;
   }
   
   .faq-question-answer-div-btn button:hover:nth-child(2) {
     color: var(--main-coral);
     background-color: var(--main-white);
   }
   
   .web-category-healine-icon-btn {
     margin: 0rem auto;
   }
 
   /* ================ */
 /* Responsive Area */
 /* ================ */
 
 @media (max-width: 768px) {
     .faq-header p {
       width: 100%;
     }
   
     .faq-body-container-col {
       padding: 0px 10px;
       width: 100%;
     }
   }
 
 
   @media screen and (min-width: 1024px) and (max-width: 1366px) {
     .faq-section{
       margin: 5rem auto;
       width: 85%;
     }
   }
   
 
   /* ================== */
 /*FAQ Design Arean End  */
 /* ==================== */

/* ========================= */
/* Privacy Design Area Start */
/* ======================= */

 .privacy-policy-section h3{
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 3rem;
  color: var(--main-blue-primary);
}

.privacy-policy-details-top{
  color: var(--main-light-black);
  font-size: 19px;
}

.privacy-policy-induvidual-div{
  margin: 2rem auto;
}

.privacy-policy-induvidual-div h4{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1.8rem;
  color: var(--main-dark-black);
}


.privacy-policy-induvidual-div p{
  font-size: 18px;
  margin: 10px auto;
}

.privacy-policy-induvidual-div p i{
  margin: 0px 10px;
  font-size: 10px;
}

/* ========================= */
/* Privacy Design Area end */
/* ======================= */
  
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .footer-container {
      width: 95%;
    }
  
    .footer-services-div h5 {
      font-size: 22px;
    }
  
    .footer-services-div p {
      font-size: 14px;
    }
  
    .footer-address-div h5 {
      font-size: 22px;
    }
  
    .footer-address-div p {
      font-size: 14px;
    }
  
    .footer-logo-div p {
      font-size: 14px;
    }
  
    .footer-bottom-section p {
      font-size: 13px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .footer-container {
      width: 95%;
    }
  }
  
  @media only screen and (max-width: 599px) {
    .footer-container {
      width: 95%;
    }
  }