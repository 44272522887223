.home-blog-section{
    margin: 1rem  auto;
    padding: 1rem 0rem;
    width: 75%;
}

.home-blog-title-div{
    text-align: center;
}

.home-blog-title-div h3{
    color: var(--main-prussian-blue);
    font-weight: 800;
    font-size: 48px;
}

.home-blog-title-div h3 span{
    color: var(--main-coral);
}

.home-blog-title-div p{
    color: var(--main-fiord-gray);
    font-weight: 400;
    font-size: 19px;
}


/* ====================== */
/* blogs Card Design */
/* ====================== */

.home-blogs-div{
    margin: 4rem auto;
}
  
  .home-blogs-card {
    width: 100%;
    height: 540px;
    padding: 15px;
    border-radius: 25px;
  }

  .home-blogs-card:hover{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
  
  .home-blogs-card .home-blogs-card-inner {
    position: relative;
    height: 300px;
    border-radius: 25px 25px 0px 25px;
    overflow: hidden;
  }
  
  .home-blogs-card .home-blogs-card-inner .home-blogs-card-box .home-blogs-card-img-box {
    position: absolute;
    inset: 0;
    transition: 0.3s all linear;
   
  }
  
  .home-blogs-card .home-blogs-card-inner .home-blogs-card-box .home-blogs-card-img-box:hover {
    transform: scale(1.1);
  }
  
  .home-blogs-card .home-blogs-card-inner .home-blogs-card-box .home-blogs-card-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* filter: grayscale(100%); */
    filter: brightness(60%);
  }

  .home-blogs-card .home-blogs-card-inner .home-blogs-card-box .home-blogs-card-img-box iframe {
    width: 120%;
    height: 90%;
    object-fit: cover !important;
    /* filter: grayscale(100%); */
    filter: brightness(60%);
  }
  
  
  .home-blogs-card-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80px;
    height: 80px;
    border-top-left-radius: 50px;
    padding: 12px;
    background-color: var(--main-white);
  }
  
  .home-blogs-card-icon::after {
    position: absolute;
    content: "";
    top: -18px;
    right: 0;
    background: transparent;
    width: 18px;
    height: 18px;
    border-bottom-right-radius: 30px;
    box-shadow: 10px 10px 0 5px var(--main-white);
  }
  
  .home-blogs-card-icon::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: -18px;
    background: transparent;
    width: 18px;
    height: 18px;
    border-bottom-right-radius: 30px;
    box-shadow: 10px 10px 0 10px var(--main-white);
  }
  
  .home-blogs-card-icon span {
    transform: rotate(-40deg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    border-radius: 50px;
    background-color: var(--main-coral);
    transition: 0.3s all linear;
  }
  
  .home-blogs-card-icon i {
    color: var(--main-white);
    font-size: 24px;
  }
  .home-blogs-card-icon:hover span {
    cursor: pointer;
    transform: scale(1.1);
  }
  
  .home-blogs-card-content h3 {
    font-size: 22px;
    margin: 10px auto 10px auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--main-fiord-gray);
  }

  .home-blogs-card-content p{
    padding: 0px;
    margin: 0px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--main-fiord-gray);
  }

  .home-blogs-card-content-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .home-blogs-card-content-top p{
    font-size: 14px;
    font-weight: 500;
    margin: 30px 0px 10px 0px;
  }

  .home-blogs-card-content-top p:nth-child(1){
    color: var(--main-coral);
  }

  .home-blogs-card-content-bottom p{
    color: var(--main-fiord-gray);
    font-weight: 500;
    font-size: 17px;
    margin: 10px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: var(--main-fiord-gray);
  }

  .home-blogs-card-content-bottom p i{
    margin-right: 5px;
    font-size: 17px;
  }

  /* =================== */
  /* Blog Details Design */
  /* =================== */

  .blog-details-discription-div{
    margin: 3rem auto;
  }

  .blog-details-discription-div h3{
    text-align: center;
    font-size: 42px;
    font-weight: 600;
    margin: 2rem auto;
    color: var(--main-blue-primary);
  }

  .blog-details-description p{
    font-size: 18px;
    font-weight: 400;
    color: var(--main-fiord-gray);
  }

  .blog-post-date-div p{
    font-size: 15px;
    color: #737373;
    font-weight: 500;
    margin: 2rem 0rem;
  }

  .blog-details-description img{
width: 100%;
border-radius: 10px;
  }

  .recent-posts-div h5{
    margin: 2.5rem auto;
    padding: 15px 0px;
    border-bottom: 1px solid #737373;
  }

  .recent-posts-details-div{
    margin-bottom: 2rem;
  }

  .recent-posts-details-div img{
    width: 100%;
    height: 100px;
    border-radius: 10px;
  }

  .recent-posts-details-div h4{
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
  }

  .recent-posts-details-div h4:hover{
    text-decoration: underline;
  }

  .recent-posts-details-div p{
    font-size: 14px;
    color: var(--main-coral);
    font-weight: 500;
  }



  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .home-blog-section{
      width: 95%;
  }
  }


  @media only screen and (max-width: 599px) {
    .home-blog-section{
      width: 100%;
  }

  .home-blog-title-div h3{
    font-size: 25px;
}

.home-blog-title-div p{
    font-size: 14px;
}

.home-blogs-div{
  margin: 1rem auto;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-blog-section{
      width: 95%;
  }
  }