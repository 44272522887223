.home-business-partner-section {
    margin: 3rem auto;
    width: 75%;
  }
  
  .business-partener-img-card {
    padding: 20px 80px;
    height: 100px;
    text-align: center;
  }
  
  .business-partener-img {
    width: 80% !important;
    height: 100% !important;
    transform: scale(1.2);
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .home-business-partner-section {
      margin: 1rem auto;
      width: 95%;
    }
  }
  
  @media only screen and (max-width: 599px) {
    .home-business-partner-section {
      margin: 3rem auto;
      width: 100%;
    }
  
    .business-partener-img-card-div {
      width: 80%;
    }
  
    .business-partener-img {
      width: 100% !important;
      height: 100% !important;
      transform: scale(1.2);
      margin-left: 2.5rem;
    }
  }