.home-services-section{
    margin: auto;
    width: 75%;
    padding: 2rem 0rem;
}

.home-title-div{
    text-align: center;
}

.home-title-div h3{
    color: var(--main-prussian-blue);
    font-weight: 800;
    font-size: 48px;
}

.home-title-div h3 span{
    color: var(--main-coral);
}

.home-title-div p{
    color: var(--main-fiord-gray);
    font-weight: 400;
    font-size: 19px;
}

/* ====================== */
/* Services Card Design */
/* ====================== */

.home-service-div{
    margin: 4rem auto;
}
  
  .home-services-card {
    width: 100%;
    height: 460px;
    padding: 15px;
    border-radius: 25px;
  }

  .home-services-card:hover{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
  
  .home-services-card .home-services-card-inner {
    position: relative;
    height: 300px;
    border-radius: 25px 25px 0px 25px;
    overflow: hidden;
  }
  
  .home-services-card .home-services-card-inner .home-services-card-box .home-services-card-img-box {
    position: absolute;
    inset: 0;
    transition: 0.3s all linear;
   
  }
  
  .home-services-card .home-services-card-inner .home-services-card-box .home-services-card-img-box:hover {
    transform: scale(1.1);
  }
  
  .home-services-card .home-services-card-inner .home-services-card-box .home-services-card-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* filter: grayscale(100%); */
    filter: brightness(60%);
   
  
  }


  .home-services-card .home-services-card-inner .home-services-card-box .home-services-card-img-box iframe {
    width: 120%;
    height: 90%;
    object-fit: cover;
    /* filter: grayscale(100%); */
    filter: brightness(60%);
   
  
  }
  
  .home-services-card-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80px;
    height: 80px;
    border-top-left-radius: 50px;
    padding: 12px;
    background-color: var(--main-white);
  }
  
  .home-services-card-icon::after {
    position: absolute;
    content: "";
    top: -18px;
    right: 0;
    background: transparent;
    width: 18px;
    height: 18px;
    border-bottom-right-radius: 30px;
    box-shadow: 10px 10px 0 5px var(--main-white);
  }
  
  .home-services-card-icon::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: -18px;
    background: transparent;
    width: 18px;
    height: 18px;
    border-bottom-right-radius: 30px;
    box-shadow: 10px 10px 0 10px var(--main-white);
  }
  
  .home-services-card-icon span {
    transform: rotate(-40deg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    border-radius: 50px;
    background-color: var(--main-coral);
    transition: 0.3s all linear;
  }
  
  .home-services-card-icon i {
    color: var(--main-white);
    font-size: 24px;
  }
  .home-services-card-icon:hover span {
    cursor: pointer;
    transform: scale(1.1);
  }
  
  .home-services-card-content h3 {
    font-size: 24px;
    margin: 20px auto 10px auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--main-fiord-gray);
  }

  .home-services-card-content p{
    padding: 0px;
    margin: 0px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--main-fiord-gray);
  }

  .home-services-card-content-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .home-services-card-content-top p{
    font-size: 14px;
    font-weight: 500;
    margin: 30px 0px;
  }

  .home-services-card-content-top p:nth-child(1){
    color: var(--main-coral);
  }


/* ===================== */
/* Button Design Area */
/* ===================== */
.custom-button-div{
    text-align: center;
    margin-top: 5rem;
}
.custom-common-btn {
    width: 150px;
    height: 45px;
    color: var(--main-white);
    border-radius: 8px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: rgb(238, 149, 34, 1);
    background: linear-gradient(0deg, rgba(238, 149, 34, 1) 0%, rgba(255, 138, 69, 1) 100%); 
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }


  .btn-3 {
    line-height: 45px;
    padding: 0;
    border: none;
    
  }
  .btn-3 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .btn-3:before,
  .btn-3:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
     background: var(--main-coral);
    transition: all 0.3s ease;
  }
  .btn-3:before {
    height: 0%;
    width: 2px;
  }
  .btn-3:after {
    width: 0%;
    height: 2px;
  }
  .btn-3:hover{
     background: transparent;
    box-shadow: none;
    
  }
  .btn-3:hover:before {
    height: 100%;
  }
  .btn-3:hover:after {
    width: 100%;
  }
  .btn-3 span:hover{
     color: var(--main-coral);
  }
  .btn-3 span:before,
  .btn-3 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: var(--main-coral);
    transition: all 0.3s ease;
  }
  .btn-3 span:before {
    width: 2px;
    height: 0%;
  }
  .btn-3 span:after {
    width: 0%;
    height: 2px;
  }
  .btn-3 span:hover:before {
    height: 100%;
  }
  .btn-3 span:hover:after {
    width: 100%;
  }

  /* ------------------------ */

  .services-details-section{
    margin: 5rem auto;
    width: 75%;
}

.services-details-project-section{
    margin: 7rem auto;
}

.services-details-development-process-div{
    margin: 8rem auto;
}

/* =========================== */
/* Services Expertise Design */
/* =========================== */

.services-details-expertise-div h3{
    color: var(--main-prussian-blue);
    font-weight: 800;
    font-size: 48px;
    text-align: center;
}

.services-details-expertise-div h3 span{
    color: var(--main-coral);
}

.services-details-expertise-div-p{
    color: var(--main-fiord-gray);
    font-weight: 400;
    font-size: 19px;
    text-align: center;
    margin: 10px auto;
}

.services-details-expertise{
    margin: 4rem auto;
    width: 100%;
}

.services-details-expertise-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid var(--bs-cyan-white);
    padding: 10px;
    margin: 0px auto 15px auto;
    transition: transform 0.3s ease;
}

.services-details-expertise-title:hover{
    transform: translateY(-5px);
}

.services-details-expertise-title p{
    font-size: 19px;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
    
}

.services-details-expertise-title .fa-cloud{
    background-color: var(--main-bright-blue);
    color: var(--main-white);
    font-size: 14px;
    padding: 6px 5px;
    border-radius: 10px;
    margin-right: 10px;
}

.services-details-expertise-title .fa-arrow-right{
    color: var(--main-bright-blue);
}

.services-details-expertise-description h3{
    font-size: 30px;
    color: var(--main-blue-secondery);
    margin-bottom: 2rem;
    font-weight: 600;
    text-align: start;
      }
    
    
          .services-details-expertise-description p{
            font-size: 18px;
            color: var( --main-blue-secondery);
            font-weight: 400;
              }
    
              .services-details-expertise-description p i{
                color: var(--main-fiord-gray);
                margin-right: 10px;
              }


/* =================== */
/* Process Card Design */
/* =================== */

.services-details-development-process-div h3{
    text-align: center;
    margin: 3rem auto 1rem auto;
    font-size: 30px;
    color: var(--main-blue-secondery);
}

.services-details-development-process-div-p{
    color: var(--main-fiord-gray);
    font-weight: 400;
    font-size: 19px;
    margin-bottom: 3rem;
    text-align: center;
}

.services-process-card{
    margin: 10px auto;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 6px;
    height: 100%;
    transition: 0.3s;
    padding: 30px 30px 10px 30px;
  }
.services-process-card:hover {
  box-shadow: 1px 10px 16px 0 rgba(0,0,0,0.3);
}
  .services-process-card-title{
    text-align: center;
    color: #666666;
    font-weight: bold;
  }
  .services-process-card-text{
        font-weight: 500;
  color: #66686b;
  }
  .services-process-card-image{
    text-align: center;
    margin: 5vh 0;
  }

  .services-process-card-image img{
    width: 30%;
    height: 100px;
  }


  .services-process-card-text{
    text-align: start;
  }

  .services-process-title-heading{
    font-size: 3em;
    text-align: center;
    margin: 4%;
    font-weight: bold;
  }


  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .services-details-section{
      margin: 5rem auto;
      width: 95%;
  }
  }
  


  /* Responsive Design Area */

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 599px) {
    .home-services-section{
        width: 100%;
    }

    .home-title-div h3{
        font-size: 25px;
    }

    .home-title-div p{
        font-size: 14px;
    }

  .home-service-div{
    margin: 1rem auto;
}
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 767px) {}
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-services-section{
        width: 95%;
    }

    .home-service-div{
      margin: 1rem auto;
  }
    
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    .home-services-section{
        width: 95%;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .home-services-section{
      width: 95%;
  }
  }